<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <router-view />
  </n-config-provider>
</template>

<script>
import NConfigProvider from 'naive-ui'

export default {
  data() {
    return {
      themeOverrides: {
        common: {
          primaryColor: 'rgb(0, 122, 255)'
        },
      }
    }
  },
  mounted() {
    // ElementPlus主题色
    document.body.style.setProperty('--el-color-primary', 'rgb(0, 122, 255)');
  },
  beforeUnmount() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
