import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'chat',
      component: () => import('../views/ChatView.vue'),
      meta: { title: 'iEstimates' }
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import('../views/AuthView.vue'),
      meta: { title: 'iEstimates | Auth' }
    },
    {
      path: '/document',
      name: 'document',
      component: () => import('../views/DocumentView.vue'),
      meta: { title: 'iEstimates | Doc' }
    },
    {
      path: '/payment/result',
      name: 'payment_result',
      component: () => import('../views/PaymentResultView.vue'),
      meta: { title: 'iEstimates | PaymentResult' }
    }
  ]
})

router.beforeEach((to, from, next) => {
  // 设置标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  // 如果没有找到路由
  if (to.matched.length === 0) {
    next('/');
  }
  window.scrollTo(0, 0)

  next();
})

export default router
